import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/release-notes.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ThankYou = makeShortcode("ThankYou");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p {...{
      "className": "date"
    }}>{`17th May 2019`}</p>


    <h2 {...{
      "id": "new-features",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#new-features",
        "aria-label": "new features permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`New features`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`You can now bind your Service to a certain HTTP method or enable HTTP content-type negotiation very easily with the new `}<inlineCode parentName="p">{`ServerBuilder.route()`}</inlineCode>{` method. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/1737"
          }}>{`#1737`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`ServerBuilder sb = new ServerBuilder();
sb.route()
  .get("/users/{id}")
  .delete("/users/{id}")
  .post("/users")
  .consumes(MediaType.JSON)
  .produces(MediaType.JSON_UTF_8)
  .build(new MyUserService());

// You can also configure using a lambda expression.
sb.withRoute(b -> {
    b.path("/foo")
     .build(new MyFooService());
});
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`It is now also possible to specify different settings for different services using the new `}<inlineCode parentName="p">{`route()`}</inlineCode>{` method. It means you can specify a large timeout for a certain service only conveniently. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/1737"
          }}>{`#1737`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`ServerBuilder sb = new ServerBuilder();
sb.route()
  .path("/long_poll")
  .requestTimeoutMillis(0) // Disable timeout for /service.
  .build(new MyLongPollingService());
sb.route()
  .path("/get_now")
  .build(new MyOtherService()); // Use the default timeout.
\`\`\`java

`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`We revamped our `}<inlineCode parentName="p">{`HttpHeaders`}</inlineCode>{` API to make it cleaner and safer. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/1731"
          }}>{`#1731`}</a></p>
        <ul parentName="li">
          <li parentName="ul">
            <p parentName="li"><inlineCode parentName="p">{`HttpHeaders`}</inlineCode>{` has been split into three types:`}</p>
            <ul parentName="li">
              <li parentName="ul"><inlineCode parentName="li">{`RequestHeaders`}</inlineCode>{` for request headers with `}<inlineCode parentName="li">{`:method`}</inlineCode>{` and `}<inlineCode parentName="li">{`:path`}</inlineCode>{` header`}</li>
              <li parentName="ul"><inlineCode parentName="li">{`ResponseHeaders`}</inlineCode>{` for response headers with `}<inlineCode parentName="li">{`:status`}</inlineCode>{` header`}</li>
              <li parentName="ul"><inlineCode parentName="li">{`HttpHeaders`}</inlineCode>{` for trailers and other headers`}</li>
              <li parentName="ul"><inlineCode parentName="li">{`RequestHeaders`}</inlineCode>{` and `}<inlineCode parentName="li">{`ResponseHeaders`}</inlineCode>{` extend `}<inlineCode parentName="li">{`HttpHeaders`}</inlineCode>{`.`}</li>
            </ul>
          </li>
          <li parentName="ul">
            <p parentName="li"><inlineCode parentName="p">{`HttpHeaders`}</inlineCode>{` and its subtypes are immutable and thus must be built using a factory method or a builder.`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`Quick examples:`}</p>
            <pre parentName="li"><code parentName="pre" {...{
                "className": "language-java"
              }}>{`RequestHeaders reqHdrs = RequestHeaders.of(HttpMethod.GET, "/get",
                                           HttpHeaderNames.ACCEPT, MediaType.JSON_UTF_8);
RequestHeaders newReqHdrs = reqHdrs.toBuilder()
                                   .add("foo", "bar")
                                   .build();
ResponseHeaders resHdrs = ResponseHeaders.of(200 /* OK */);

HttpHeaders hdrs = HttpHeaders.builder()
                              .add("alice", "bob");
                              .build();
HttpHeaders newHdrs = hdrs.withMutations(builder -> {
    builder.add("charlie", "debora");
});
`}</code></pre>
          </li>
          <li parentName="ul">
            <p parentName="li">{`See `}<inlineCode parentName="p">{`HttpHeaders`}</inlineCode>{` Javadoc for more examples.`}</p>
          </li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now test your Armeria app with JUnit 5. A new module `}<inlineCode parentName="p">{`armeria-testing-junit5`}</inlineCode>{` has been added with the following extensions: `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/1736"
          }}>{`#1736`}</a></p>
        <ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`com.linecorp.armeria.testing.junit.server.ServerExtension`}</inlineCode></li>
          <li parentName="ul"><inlineCode parentName="li">{`com.linecorp.armeria.testing.junit.server.SelfSignedCertificateExtension`}</inlineCode></li>
          <li parentName="ul"><inlineCode parentName="li">{`com.linecorp.armeria.testing.junit.common.EventLoopGroupExtension`}</inlineCode></li>
          <li parentName="ul"><inlineCode parentName="li">{`com.linecorp.armeria.testing.junit.common.EventLoopExtension`}</inlineCode></li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now customize the behavior of gRPC JSON marshaller. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/1696"
          }}>{`#1696`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/1753"
          }}>{`#1753`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`ServerBuilder sb = new ServerBuilder();
sb.service(new GrpcServiceBuilder()
               .addService(new MyServiceImpl())
               .supportedSerializationFormats(GrpcSerializationFormats.values())
               .jsonMarshallerCustomizer(marshaller -> {
                   marshaller.preservingProtoFieldNames(true);
               })
               .build());
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now write a unary gRPC client without depending on grpc-java at all. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/1703"
          }}>{`#1703`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/1748"
          }}>{`#1748`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/1751"
          }}>{`#1751`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`HelloRequest req = HelloRequest.newBuilder()
                               .setName("Alice")
                               .build();
UnaryGrpcClient client = new UnaryGrpcClient(HttpClient.of("http://127.0.0.1:8080"));
byte[] resBytes = client.execute("/com.example.HelloService/Greet",
                                 req.toByteArray()).join();
HelloResponse res = HelloResponse.parseFrom(resBytes);
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now use `}<inlineCode parentName="p">{`GrpcServiceRegistrationBean`}</inlineCode>{` to register a gRPC service when using Spring Boot integration. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/1749"
          }}>{`#1749`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`@Bean
public GrpcServiceRegistrationBean helloService() {
    return new GrpcServiceRegistrationBean()
        .setServiceName("helloService")
        .setService(new GrpcServiceBuilder()
                        .addService(new HelloService())
                        .supportedSerializationFormats(GrpcSerializationFormats.values())
                        .enableUnframedRequests(true)
                        .build())
        .setDecorators(LoggingService.newDecorator())
        .setExampleRequests(List.of(ExampleRequest.of(HelloServiceGrpc.SERVICE_NAME,
                                                      "Hello",
                                                      HelloRequest.newBuilder()
                                                                  .setName("Armeria")
                                                                  .build())));
}
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now use `}<inlineCode parentName="p">{`wildcard`}</inlineCode>{` pattern when specifying built-in properties in Logback `}<inlineCode parentName="p">{`RequestContextExportingAppender`}</inlineCode>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/489"
          }}>{`#489`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/1742"
          }}>{`#1742`}</a></p>
      </li>
    </ul>
    <h2 {...{
      "id": "bug-fixes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#bug-fixes",
        "aria-label": "bug fixes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Bug fixes`}</h2>
    <ul>
      <li parentName="ul">{`Trailing slashes in a path pattern is now handled correctly. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1741"
        }}>{`#1741`}</a></li>
      <li parentName="ul">{`It is now disallowed to apply `}<inlineCode parentName="li">{`CorsDecorator`}</inlineCode>{` more than once. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1740"
        }}>{`#1740`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`HttpTracingClient`}</inlineCode>{` and `}<inlineCode parentName="li">{`HttpTracingService`}</inlineCode>{` now adds a valid addressable `}<inlineCode parentName="li">{`http.url`}</inlineCode>{` tag. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1733"
        }}>{`#1733`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1762"
        }}>{`#1762`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`SessionProtocol`}</inlineCode>{` and `}<inlineCode parentName="li">{`SerializationFormat`}</inlineCode>{` are now added to `}<inlineCode parentName="li">{`http.protocol`}</inlineCode>{` and `}<inlineCode parentName="li">{`http.serfmt`}</inlineCode>{` tag instead.`}</li>
    </ul>
    <h2 {...{
      "id": "breaking-changes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#breaking-changes",
        "aria-label": "breaking changes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Breaking changes`}</h2>
    <ul>
      <li parentName="ul">{`Artifact armeria-testing has been renamed to `}<inlineCode parentName="li">{`armeria-testing-junit4`}</inlineCode>{`. Please update your project dependencies. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1736"
        }}>{`#1736`}</a></li>
      <li parentName="ul">{`Many places in the public API that use `}<inlineCode parentName="li">{`HttpHeaders`}</inlineCode>{` as a parameter or a return value have been changed due to the revamped `}<inlineCode parentName="li">{`HttpHeaders`}</inlineCode>{` API. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1731"
        }}>{`#1731`}</a></li>
      <li parentName="ul">{`The following `}<inlineCode parentName="li">{`ServerBuilder`}</inlineCode>{` methods were removed:`}
        <ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`virtualHost(VirtualHost)`}</inlineCode></li>
          <li parentName="ul"><inlineCode parentName="li">{`defaultVirtualHost(VirtualHost)`}</inlineCode></li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "deprecations",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#deprecations",
        "aria-label": "deprecations permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Deprecations`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`The default prefix found in various configuration properties has been deprecated. Use the property setters without the default prefix. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/1737"
          }}>{`#1737`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`ServerBuilder sb = new ServerBuilder();
// Do NOT use this:
sb.defaultRequestTimeout(...);
// Use this:
sb.requestTimeout(...);
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`HttpHeaders.EMPTY`}</inlineCode>{` has been deprecated. Use `}<inlineCode parentName="p">{`HttpHeaders.of()`}</inlineCode>{`.`}</p>
      </li>
    </ul>
    <h2 {...{
      "id": "dependencies",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#dependencies",
        "aria-label": "dependencies permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Dependencies`}</h2>
    <ul>
      <li parentName="ul">{`Dropwizard Metrics 4.0.5 -> 4.1.0`}</li>
      <li parentName="ul">{`Jetty 9.4.17 -> 9.4.18`}</li>
      <li parentName="ul">{`Project Reactor 3.2.8 -> 3.2.9`}</li>
    </ul>
    <h2 {...{
      "id": "thank-you",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#thank-you",
        "aria-label": "thank you permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Thank you`}</h2>
    <ThankYou usernames={['anuraaga', 'codefromthecrypt', 'delegacy', 'ejona86', 'hyangtack', 'huydx', 'karthikraman22', 'masonshin', 'matsumana', 'minwoox', 'trustin']} mdxType="ThankYou" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      